import React from "react"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"

import FaqLayout from "../../components/faq-layout"

export default () => (
  <FaqLayout subMenuActivo="pagos">
    <Accordion defaultActiveKey="0">
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          <h6>
            <a className="collapsed" href="#a" data-toggle="collapse">
              ¿Cómo pago el seguro contratado?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <p>
              El seguro será abonado por medio de cuponera, del débito automático en la tarjeta de
              crédito o cuenta bancaria mediante el CBU que selecciones.
            </p>
            <p>En el caso de la tarjeta de crédito no afecta el límite de compra.</p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Cuándo me cobran la primera cuota?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <p>
              La primera cuota será debitada dentro de los 30 días posteriores a la emisión de la
              póliza.
            </p>
            <p>
              En caso de utilizar tarjeta de crédito el cobro tendrá efectividad en el próximo
              cierre de la misma, si se trata de un débito por CBU en cuenta bancaria el cobro
              podría entrar dentro del mes en curso o del mes siguiente inmediato a la contratación
              de la póliza.
            </p>
            <p>
              En los casos de pago a través de CBU la cuenta debe tener fondos para que se realice
              el débito respectivo.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Necesito hacer algún pago o aviso extra si viajo al exterior con el auto?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <p>
              No. La cobertura será automática en Argentina y en países limítrofes, por lo tanto, no
              se necesita dar previo aviso a la Compañía para estar cubierto.
            </p>
            <p>
              El precio tampoco sufrirá modificaciones. Recordá que cuando viajes al exterior es
              necesario llevar el certificado Mercosur adjunto a tu póliza.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="3">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              En caso de querer dar de baja mi seguro, ¿cuánto tiempo antes debo notificar a la
              compañía para que no ingrese el pago del mes siguiente?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <p>
              Se aconseja gestionar la baja con 15 días de anterioridad indicando la fecha expresa
              de anulación de póliza para poder notificar a las aseguradoras.
            </p>
            <p>
              Los mecanismos de cobranzas, por lo general, acceden a mes vencido, por lo tanto,
              debes tener en cuenta que puede ingresar un cobro posterior a la baja que corresponde
              al período anterior en el cual la cobertura si estuvo vigente.
            </p>
            <p>En caso de que el cobro no corresponda se efectuara un reintegro a tu favor.</p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </FaqLayout>
)
